import React from "react"

const Statistics = () => {
  return (
    <div className="w-11/12 mx-auto my-8 mb-20 md:mb-40">
      <h3 className="text-center my-10 mb-6 text-2xl font-bold">Our impact</h3>

      <div className="rounded-2xl md:rounded-xl bg-primary-main flex flex-col md:flex-row flex-wrap gap-7 text-light py-4 md:py-10 px-10 lg:px-16 md:divide-y-0 md:divide-x divide-neutral-200">
        <div className="flex flex-col items-center gap-1.5 flex-1 pt-8 md:py-0">
          <h3 className="text-4xl font-bold">35k+</h3>
          <p className="text-[0.9rem] text-center tracking-wide font-[210] w-11/12 md:w-10/12 mx-auto">
            Active students daily
          </p>
        </div>

        <hr className="w-3/12 mx-auto md:hidden" />

        <div className="flex flex-col items-center gap-1.5 flex-1 py-6 md:py-0">
          <h3 className="text-4xl font-bold">24%</h3>
          <p className="text-[0.9rem] text-center tracking-wide font-[210] w-11/12 md:w-10/12 mx-auto">
            Improvement in overall academic performance
          </p>
        </div>

        <hr className="w-3/12 mx-auto md:hidden" />

        <div className="flex flex-col items-center gap-1.5 flex-1 pb-8 md:py-0">
          <h3 className="text-4xl font-bold">16k+</h3>
          <p className="text-[0.9rem] text-center tracking-wide font-[210] w-11/12 md:w-10/12 mx-auto">
            Students succesfully completed STEAME modules training
          </p>
        </div>
      </div>
    </div>
  )
}

export default Statistics
