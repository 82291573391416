import * as React from "react"
import Layout from "../components/layout/index"
// import Overview from "../components/overview"
import Statistics from "../components/statistics"
// import LearnOverview from "../components/learn_overview"
// import SteamOverview from "../components/steam_overview"
// import OverviewExtra from "../components/overview_extra"
import Seo from "../components/seo"
import "../css/index.css"
import Customers from "../components/customers"
import Carousel from "../components/Carousel"
import WhatWeOffer from "../components/WhatWeOffer"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <Carousel />
    <div className="">
      {/* <Overview /> */}
      {/* <LearnOverview /> */}
      {/* <SteamOverview /> */}
      {/* <OverviewExtra /> */}
      <WhatWeOffer />
      <Statistics />
      <Customers />
    </div>
  </Layout>
)

export default IndexPage
