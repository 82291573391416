import { Link } from "gatsby"
import React from "react"
import Button from "./buttons/overview_button"
import learnImage from "../assets/learnPlatform.png"
import steameImage from "../assets/steamPlatform.png"

export default function WhatWeOffer() {
  return (
    <div className="pb-5">
      <div className="flex flex-col md:flex-row gap-y-10 my-28 overflow-hidden justify-between pl-4 md:pr-0 md:pl-7">
        <div className="flex flex-col">
          <h3 className="mb-9 text-3xl font-bold">What we offer</h3>

          <div className="w-10/12">
            <p className="text-2xl mb-3">Interactive E-learning Platform</p>

            <ul className="text-neutral-500 text-lg">
              <li className="py-1.5">
                Freedom to learn in and out of the classroom
              </li>
              <li className="py-1.5">Study at your own pace</li>
              <li className="py-1.5">
                Relevant curriculum-based notes and assessments
              </li>
              <li className="py-1.5">
                Interactively discuss with peers and teachers
              </li>
              <li className="py-1.5">
                Access to automatically and instantly scored KCPE/KCSE papers
              </li>
            </ul>

            <Link
              to="https://edu.angazaelimu.com/signup"
              target="_blank"
              rel="noreferrer"
            >
              <Button text="Start learning" className="mt-6" />
            </Link>
          </div>
        </div>

        <div className="relative">
          <div className="">
            <img src={learnImage} className="h-[34rem] rounded-l-xl" alt="" />
          </div>
        </div>
      </div>

      {/* STEAME */}
      <div className="flex flex-col md:flex-row gap-y-10 my-10 md:mt-48 md:my-28 overflow-hidden justify-between pl-4 md:pr-0 md:pl-7">
        <div className="flex flex-col flex-1 flex-wrap">
          <div className="w-10/12">
            <div className="mb-3">
              <p className="text-2xl">Innovators STEAME Training</p>
              <p className="text-sm text-dark/80">
                (Science Technology Engineering Art Math Entrepreneurship)
              </p>
            </div>

            <ul className="text-neutral-500 text-lg">
              <li className="py-1.5">Internet of Things(IoT)</li>
              <li className="py-1.5">Robotics</li>
              <li className="py-1.5">Arduino and Raspberry Pi</li>
              <li className="py-1.5">Software development</li>
              <li className="py-1.5">Web app development</li>
              <li className="py-1.5">Mobile app development</li>
              <li className="py-1.5">Sales and Marketing</li>
            </ul>

            <Link
              to="https://steam.angazaelimu.com/signup"
              target="_blank"
              rel="noreferrer"
            >
              <Button text="Start learning" className="mt-6" />
            </Link>
          </div>
        </div>

        <div className="relative">
          <div className="">
            <img src={steameImage} className="h-[34rem] rounded-l-xl" alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}
