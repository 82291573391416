import React from "react"
import arrowLeft from "../../assets/arrowLeft.svg"
import arrowRight from "../../assets/arrowRight.svg"
// import * as style from "./customer_item.module.css"

const CustomerItem = ({ item, onPrev, onNext }) => {
  return (
    <div className="w-full p-4 md:p-2.5 rounded-2xl bg-light drop-shadow-xl my-10 shadow">
      <div className="flex flex-col md:flex-row gap-6 items-center">
        <img
          src={item.img}
          alt="quote"
          className="w-10/12 md:w-44 md:h-44 rounded-lg aspect-auto"
        />

        <div className="flex flex-col self-start flex-1 pt-5 md:py-1.5 gap-10 md:gap-5 h-full">
          <p className="text-center md:text-left text-base md:text-sm tracking-wide leading-tight text-dark md:w-11/12 w-9/12 mx-auto md:mx-0">
            {item.text}
          </p>

          <div className="flex flex-col justify-between flex-1">
            <div className="text-center md:text-left">
              <h4 className="text-lg font-semibold">{item.name}</h4>
              <p className="text-xs text-neutral-400 -mt-1">{item.detail}</p>
            </div>
          </div>

          <div className="flex gap-2 items-center justify-center md:justify-start">
            <img
              src={arrowLeft}
              className="cursor-pointer h-5"
              onClick={() => onPrev()}
              alt=""
            />
            <img
              src={arrowRight}
              className="cursor-pointer h-5"
              onClick={() => onNext()}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomerItem
