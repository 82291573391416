import React, { useState, useEffect } from "react"

import CustomerItem from "../customer_item"
import { customers } from "../../data"

import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"

const Customers = () => {
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    const rotateTestimonial = setInterval(() => {
      currentIndex === customers.length - 1
        ? setCurrentIndex(0)
        : setCurrentIndex(curr => curr + 1)
    }, 5500)

    return () => clearInterval(rotateTestimonial)
  }, [currentIndex])

  return (
    <div className="px-5">
      <h3 className="text-center my-10 mb-6 text-2xl font-bold">
        What our customers are saying
      </h3>

      <div className="md:w-5/6 lg:w-4/6 mx-auto pb-16">
        <CustomerItem
          item={customers[currentIndex]}
          onPrev={
            currentIndex === 0
              ? () => setCurrentIndex(customers.length - 1)
              : () => setCurrentIndex(curr => curr - 1)
          }
          onNext={
            currentIndex === customers.length - 1
              ? () => setCurrentIndex(0)
              : () => setCurrentIndex(curr => curr + 1)
          }
        />
      </div>
    </div>
  )
}

export default Customers
