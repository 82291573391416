import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"

import { Autoplay, EffectFade } from "swiper"
import { heroImages } from "../data"

import "swiper/css"
import "swiper/css/autoplay"
import "swiper/css/effect-fade"

export default function Carousel() {
  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={30}
      effect="fade"
      loop={true}
      grabCursor
      autoplay={{
        delay: 5000,
        pauseOnMouseEnter: true,
        disableOnInteraction: false,
      }}
      modules={[Autoplay, EffectFade]}
      className="mySwiper w-full h-80 md:h-[620px] select-none"
    >
      {heroImages.map(hero => (
        <SwiperSlide>
          <div
            key={hero.id}
            className="w-full h-80 md:h-[620px] relative flex justify-center items-center"
            style={{
              background: `url(${hero.img}`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          >
            <div className="absolute top-0 right-0 left-0 bottom-0 bg-dark/75 opacity-10"></div>
            <p className="text-light font-bold text-3xl md:text-4xl z-10 w-4/5 md:w-3/5 mx-auto text-center leading-tight md:leading-[3rem]">
              {hero.text}
            </p>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}
